import './App.css';
import React, {Suspense, useEffect} from "react";
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import {useAuth0} from "./react-auth0-spa";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import GroupsIcon from '@material-ui/icons/Group';
import ForumIcon from '@material-ui/icons/Forum';
import NotificationsIcon from '@material-ui/icons/Notifications';
import EngineeringIcon from '@mui/icons-material/Engineering';
import FolderIcon from '@mui/icons-material/Folder';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import PaymentsIcon from '@material-ui/icons/MonetizationOn';
import DownloadAppScreen from './screens/DownloadAppScreen';
import displayError from './screens/ErrorScreen';
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";
import GET_NOTIFICATIONS from './graphql/getNotifications';
import { Newspaper } from '@mui/icons-material';
const Forum = React.lazy(() => import('./screens/Forum/Groups'));
const Clients = React.lazy(() => import('./screens/Clients/Clients'));
const Groups = React.lazy(() => import('./screens/Groups'));
const Employees = React.lazy(() => import('./screens/Employees/Employees'));
const Files = React.lazy(() => import("./screens/Files/Groups"))
const Entries = React.lazy(() => import("./screens/Entries/Groups"))
const Payments = React.lazy(() => import("./screens/Payments/Groups"))
const Notification = React.lazy(() => import("./screens/Notification/Notifications"))

class ErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
		this.state = {error: ''};
		this.state = {openErrorDialog: true};
	}

	componentDidCatch(error, info) {
		// console.log("IN COMPONENT DID CATCH")
		// Display fallback UI
		this.setState({hasError: true});
		this.setState({error: error});
	}

	render() {
		if (this.state.hasError) {
			// You can render any custom fallback UI
			return displayError(this.state.error);
		}
		return this.props.children;
	}
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	drawer: {
		[theme.breakpoints.up('sm')]: {
			width: drawerWidth,
			flexShrink: 0,
		},
	},
	appBar: {
		[theme.breakpoints.up('sm')]: {
			width: `calc(100% - ${drawerWidth}px)`,
			marginLeft: drawerWidth,
		},
		backgroundColor: '#b2d231',
	},
	menuButton: {
		marginRight: theme.spacing(2),
		[theme.breakpoints.up('sm')]: {
			display: 'none',
		},
	},
	// necessary for content to be below app bar
	toolbar: theme.mixins.toolbar,
	drawerPaper: {
		width: drawerWidth,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		overflow: "hidden",
		maxWidth: "100vw"
	},
}));

function usePersistedState(key, defaultValue) {
	const [state, setState] = React.useState(
		() => JSON.parse(localStorage.getItem(key)) || defaultValue
	);
	useEffect(() => {
		localStorage.setItem(key, JSON.stringify(state));
	}, [key, state]);
	return [state, setState];
}

function Admin() {
	const [component, setComponent] = usePersistedState('component', 'Forum')
	const classes = useStyles();
	const theme = useTheme();
	const [mobileOpen, setMobileOpen] = React.useState(false);
	const {user, logout} = useAuth0();
	const isClient = user["https://acs.neuralsolutions.eu/roles"].includes("client")
	const isEmployee= user["https://acs.neuralsolutions.eu/roles"].includes("employees")
	const isAdmin = user["https://acs.neuralsolutions.eu/roles"].includes("admin")
	const isSuperAdmin = user["https://acs.neuralsolutions.eu/roles"].includes("super_admin")
	// console.log({isAdmin, isSuperAdmin, isEmployee, isClient})
	const userType = user["https://acs.neuralsolutions.eu/roles"].includes("super_admin") ? "super_admin" :
		(user["https://acs.neuralsolutions.eu/roles"].includes("admin") ? "admin" :
			(user["https://acs.neuralsolutions.eu/roles"].includes("employees") ? "employees" : "client")
		)

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};
	console.log(user)
	const [error, setError] = React.useState('');
	const [openErrorDialog, setErrorDialogOpen] = React.useState(true);

	const {data, loading, neterror, refetch} = useQuery(GET_NOTIFICATIONS, {
		pollInterval: 60000,
	})

	console.log(userType)
	console.log(data)

	if(userType === "client")
		return <>
			<ListItem button selected={component === 'Log Out'} onClick={() => {
				logout({returnTo: window.location.origin})
			}} key={'Log Out'}>
				<ListItemIcon>
					<ExitToAppIcon/>
				</ListItemIcon>
				<ListItemText primary={'Log Out'}/>
			</ListItem>
			<DownloadAppScreen/>
		</>


	const drawer = (
		<div>
			<img style={{width: "80px", padding: "10px 0px 10px 0px", background: "#969591", width: "100%", height: "15%", objectFit: "cover"}} src={"./favicon.ico"}/>
			<Divider/>
			<List>
				{isSuperAdmin &&
				<ListItem button selected={component === 'Notification'} onClick={() => setComponent('Notification')}
						key={'Notification'}>
					<ListItemIcon>
						<Newspaper/>
					</ListItemIcon>
					<ListItemText primary={'News'}/>
				</ListItem>
				}
				{isSuperAdmin &&
				<ListItem button selected={component === 'Employees'} onClick={() => setComponent('Employees')}
						  key={'Employees'}>
					<ListItemIcon>
						<EngineeringIcon/>
					</ListItemIcon>
					<ListItemText primary={'Employees'}/>
				</ListItem>
				}

				{isSuperAdmin &&
					<ListItem button selected={component === 'Groups'} onClick={() => setComponent('Groups')}
							  key={'Groups'}>
						<ListItemIcon>
							<GroupsIcon/>
						</ListItemIcon>
						<ListItemText primary={'Groups'}/>
					</ListItem>
				}

				{(isSuperAdmin || isAdmin) &&
					<ListItem button selected={component === 'Clients'} onClick={() => setComponent('Clients')}
							  key={'Clients'}>
						<ListItemIcon>
							<PersonIcon/>
						</ListItemIcon>
						<ListItemText primary={'Clients'}/>
					</ListItem>
				}
				<ListItem button selected={component === 'Forum'} onClick={() => setComponent('Forum')}
						  key={'Forum'}>
					<ListItemIcon>
						<ForumIcon/>
					</ListItemIcon>
					<ListItemText primary={'Forum'}/>
				</ListItem>
				<ListItem button selected={component === 'Files'} onClick={() => setComponent('Files')}
						  key={'Files'}>
					<ListItemIcon>
						<FolderIcon/>
					</ListItemIcon>
					<ListItemText primary={'Files'}/>
				</ListItem>
				<ListItem button selected={component === 'Entries'} onClick={() => setComponent('Entries')}
						  key={'Entries'}>
					<ListItemIcon>
						<AssignmentReturnIcon />
					</ListItemIcon>
					<ListItemText primary={'Entries'}/>
				</ListItem>
				{(isSuperAdmin || isAdmin) &&
				<ListItem button selected={component === 'Payments'} onClick={() => setComponent('Payments')}
						  key={'Payments'}>
					<ListItemIcon>
						<PaymentsIcon />
					</ListItemIcon>
					<ListItemText primary={'Payments'}/>
				</ListItem>
				}

				<Divider/>
				<ListItem button selected={component === 'Log Out'} onClick={() => {
					logout({returnTo: window.location.origin})
				}} key={'Log Out'}>
					<ListItemIcon>
						<ExitToAppIcon/>
					</ListItemIcon>
					<ListItemText primary={'Log Out'}/>
				</ListItem>

			</List>

			<div style={{position: "absolute", bottom: "0", width: "100%"}}>
				<Divider/>
				<Typography style={{textAlign: "center", fontSize: "12px", paddingTop: "10px"}}>Developed by:</Typography>
				<a href="https://neuralsolutions.eu/" target="_blank">
					<img style={{maxWidth: "130px", padding: "5px 0px 5px 0px"}} src={"./neural_solutions_logo.png"}/>
				</a>

			</div>
		</div>
	);

	return (
		<ErrorBoundary>
		<div className={classes.root}>
			<CssBaseline/>
			<AppBar position="fixed" className={classes.appBar}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawerToggle}
						className={classes.menuButton}
					>
						<MenuIcon/>
					</IconButton>
					<Typography variant="h6" noWrap >
						{component}
					</Typography>
				</Toolbar>
			</AppBar>
			<nav className={classes.drawer} aria-label="mailbox folders">
				<Hidden smUp implementation="css">
					<Drawer
						variant="temporary"
						anchor={theme.direction === 'rtl' ? 'right' : 'left'}
						open={mobileOpen}
						onClose={handleDrawerToggle}
						classes={{
							paper: classes.drawerPaper,
						}}
						ModalProps={{
							keepMounted: true, // Better open performance on mobile.
						}}
					>
						{drawer}
					</Drawer>
				</Hidden>
				<Hidden xsDown implementation="css">
					<Drawer
						classes={{
							paper: classes.drawerPaper,
						}}
						variant="permanent"
						open
					>
						{drawer}
					</Drawer>
				</Hidden>
			</nav>
			<main className={classes.content}>
				<div className={classes.toolbar}/>
				{component === 'Notification' &&
				<Suspense fallback={<></>}>
					{data === undefined ? (
						<div>No Notification Available</div>
					) : (
						<Notification notifications={data.getNotifications.Notifications}
						userLastReadNotificationId={data.getNotifications.Customer.last_read_notification}/>
					)}
				</Suspense>
				}

				{component === 'Employees' &&
				<Suspense fallback={<></>}>
					<Employees/>
				</Suspense>
				}

				{component === 'Groups' &&
				<Suspense fallback={<></>}>
					<Groups/>
				</Suspense>
				}

				{component === 'Clients' &&
				<Suspense fallback={<></>}>
					<Clients/>
				</Suspense>
				}

				{component === 'Forum' &&
				<Suspense fallback={<></>}>
					<Forum userType={userType} loggedInUser={user}/>
				</Suspense>
				}

				{component === 'Files' &&
				<Suspense fallback={<></>}>
					<Files userType={userType} loggedInUser={user}/>
				</Suspense>
				}

				{component === 'Entries' &&
					<Suspense fallback={<></>}>
						<Entries userType={userType} loggedInUser={user}/>
					</Suspense>
				}

				{component === 'Payments' &&
					<Suspense fallback={<></>}>
						<Payments userType={userType} loggedInUser={user}/>
					</Suspense>
				}

				{component === 'Log Out' &&
				alert("Log out")
				}

			</main>
			{error !== '' &&
			<Dialog
				open={openErrorDialog}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">{"Error"}</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">{error}</DialogContentText>
				</DialogContent>
			</Dialog>
			}
		</div>
		</ErrorBoundary>
	);
}

export default Admin;
